<template>
  <h1>OKR</h1>
</template>

<script>
export default {
  name: "OKR"

}
</script>

<style>

</style>